@tailwind base;
@tailwind components;

.bg-img {
  @apply bg-primary-purple;
  background-image: url('./assets/images/EthIconb.png');
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}
/* .bg-image {
  background-image: url('./assets/images/collab.png');
  background-position: right;
  background-repeat: no-repeat;
  display: block

} */
/* @media screen {
  .bg-image{
    display: none
  }
} */

@media only screen and (max-width: 700px){
  .portflio-images{
    /* height: 200px;
    width: 900px; */
  }
}

@tailwind utilities;
