@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'campton-bold';
  src: url(../fonts/Campton-BoldDEMO\ 2.otf);
}
@font-face {
  font-family: 'campton-light';
  src: url(../fonts/Campton-LightDEMO\ 2.otf);
}
body,
html {
  scroll-behavior: smooth !important;
}

.work {
  color: white;
  text-decoration: underline;
  background-color: #faaea2;
}
.boxx {
  background-color: #faaea2;
}
.txxt {
  color: #b8519d;
}
.box {
  background-color: #ff9e8f;
  height: 13rem;
  color: white;
}
.txtcolor {
  color: #ff9e8f;
}
.line {
  background-color: #ff9e8f;
  color: #ff9e8f;
  height: 5px;
  width: 80px;
  border-radius: 4px;
}

.testimoney {
  background-color: #ffddd8;
}

.navitem {
  background-color: #b8519d !important;
}

.boxer {
  position: relative;
  display: inline-block;
}
.hexagon {
  position: relative;
  display: inline-block;
}
.hexagon .hexabtn {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  position: absolute;
  z-index: 999;
  top: 50%;
  margin-left: 530px;
  width: 20%;
  background-color: #b8519d;
  border-radius: 4px;
}

.boxer .texter {
  position: absolute;
  z-index: 999;
  top: 50%;
  text-align: center;
  margin-left: 100px;
  width: 50%;
  background-color: #b8519d;
  border-radius: 4px;
  cursor: pointer;
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
#clients
  .swiper-container.swiper-container-initialized.swiper-container-horizontal {
  padding-bottom: 3rem;
}
#clients .swiper-slide.swiper-slide-active > figure {
  top: 1rem !important;
  left: -1.5rem !important;
}
#clients .swiper-slide.swiper-slide-active > figure > img {
  width: 4rem !important;
  height: 4rem !important;
}

#clients .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #b8519d;
}
@media screen and (min-width: 1024px) {
  #clients .swiper-slide.swiper-slide-active > div {
    transition: 0.3s;
    box-shadow: 0px 2px 4px rgba(102, 99, 99, 0.25);
    transform: scaleX(1.2) scaleY(1.2);
    transform-origin: center;
  }
  #clients .swiper-slide.swiper-slide-active > figure {
    top: 0rem !important;
  }
}
